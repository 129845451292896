import {
    IDBUser,
    ETables,
    IUser,
    IAPIUser,
    IAPIUserInput,
    IAccessListItem,
    IAPIAccessListItem,
    IWorkspaceData,
    IAPIWorkspaceData,
    TWorkspaceDataMap,
    TPendingTeamMembers,
} from '@ab-task/types';
import { normalize, ID2GUID, GUID2ID } from './core';
import { parseNumbersString } from '@ab-task/utils';
import {
    suspensionAPI2JS,
    suspensionDBJson2API,
    suspensionDBJson2JS,
    suspensionJS2API,
} from './suspension';
import { isEqual } from 'lodash';
import {
    onboardingAPI2JS,
    onboardingDBJson2API,
    onboardingDBJson2JS,
    onboardingJS2API,
} from './onboarding';

export function userDB2JS(dbUser: IDBUser): IUser {
    const dbGlobalOnboardingJson = dbUser.u_onboardings?.find(
        dbOnboardingJson => typeof dbOnboardingJson?.o_workspace_id !== 'number'
    );

    return {
        id: dbUser.u_id,
        globalOnboarding: dbGlobalOnboardingJson
            ? onboardingDBJson2JS(dbGlobalOnboardingJson)
            : undefined,
        workspaceDataMap: dbUser.u_workspace_access_lists
            ? dbUser.u_workspace_access_lists
                  .sort(dbWorkspaceAccessListsComparator)
                  .reduce<
                      IUser['workspaceDataMap']
                  >((accumulator, [workspaceId, projectId, roleId]) => {
                      const accessListItem: IAccessListItem = { roleId };

                      if (typeof projectId === 'number') {
                          accessListItem.projectId = projectId;
                      }

                      if (accumulator[workspaceId]) {
                          accumulator[workspaceId].accessList.push(accessListItem);
                      } else {
                          const workspaceData: IWorkspaceData = {
                              accessList: [accessListItem],
                          };

                          const dbSuspensionJson = dbUser.u_workspace_suspensions?.find(
                              dbSuspensionJson => dbSuspensionJson?.ssp_workspace_id === workspaceId
                          );

                          if (dbSuspensionJson) {
                              workspaceData.suspension = suspensionDBJson2JS(dbSuspensionJson);
                          }

                          const dbOnboardingJson = dbUser.u_onboardings?.find(
                              dbOnboardingJson => dbOnboardingJson?.o_workspace_id === workspaceId
                          );

                          if (dbOnboardingJson) {
                              workspaceData.onboarding = onboardingDBJson2JS(dbOnboardingJson);
                          }

                          accumulator[workspaceId] = workspaceData;
                      }

                      return accumulator;
                  }, {})
            : {},
        invitedToWorkspaceIds: dbUser.u_invited_to_workspace_ids
            ? parseNumbersString(dbUser.u_invited_to_workspace_ids)
            : [],
        password: normalize(dbUser.u_password),
        email: dbUser.u_email,
        firstname: normalize(dbUser.u_firstname),
        lastname: normalize(dbUser.u_lastname),
        displayName: normalize(dbUser.u_display_name),
        calculatedName: normalize(dbUser.u_calculated_name),
        timezone: dbUser.u_timezone,
        status: dbUser.u_status,
        avatarS3Key: normalize(dbUser.u_avatar_s3_key),
        paddleSubscriptionId: normalize(dbUser.u_paddle_subscription_id),
        paddleSubscription:
            typeof dbUser.u_paddle_subscription_id === 'number'
                ? { id: dbUser.u_paddle_subscription_id }
                : undefined,
        createdAt: dbUser.u_created_at,
        updatedAt: dbUser.u_updated_at,
    };
}

export function userAPI2JS(apiUser: IAPIUser): IUser {
    return {
        id: GUID2ID(apiUser.id)[1],
        globalOnboarding: apiUser.globalOnboarding
            ? onboardingAPI2JS(apiUser.globalOnboarding)
            : undefined,
        workspaceDataMap: apiUser.workspaceData.reduce<IUser['workspaceDataMap']>(
            (
                accumulator,
                {
                    workspaceId: apiWorkspaceId,
                    suspension: apiSuspension,
                    accessList: apiAccessList,
                    onboarding: apiOnboardingData,
                }
            ) => {
                const workspaceId = GUID2ID(apiWorkspaceId)[1];

                const workspaceData: IWorkspaceData = {
                    accessList: apiAccessList.map(
                        ({ projectId: apiProjectId, roleId: apiRoleId }) => {
                            const accessListItem: IAccessListItem = {
                                roleId: GUID2ID(apiRoleId)[1],
                            };

                            if (typeof apiProjectId === 'string') {
                                accessListItem.projectId = GUID2ID(apiProjectId)[1];
                            }

                            return accessListItem;
                        }
                    ),
                };

                if (apiSuspension) {
                    workspaceData.suspension = suspensionAPI2JS(apiSuspension);
                }

                if (apiOnboardingData) {
                    workspaceData.onboarding = onboardingAPI2JS(apiOnboardingData);
                }

                accumulator[workspaceId] = workspaceData;

                return accumulator;
            },
            {}
        ),
        invitedToWorkspaceIds: apiUser.invitedToWorkspaceIds.map(id => GUID2ID(id)[1]),
        email: apiUser.email,
        firstname: normalize(apiUser.firstname),
        lastname: normalize(apiUser.lastname),
        displayName: normalize(apiUser.displayName),
        calculatedName: normalize(apiUser.calculatedName),
        timezone: apiUser.timezone,
        status: apiUser.status,
        avatarS3Key: normalize(apiUser.avatarS3Key),
        paddleSubscriptionId: normalize(apiUser.paddleSubscriptionId),
        paddleSubscription: apiUser.paddleSubscription
            ? {
                  id: apiUser.paddleSubscription.id,
                  card: apiUser.paddleSubscription.card
                      ? {
                            type: apiUser.paddleSubscription.card.type,
                            lastFourDigits: apiUser.paddleSubscription.card.lastFourDigits,
                            expiryDate: apiUser.paddleSubscription.card.expiryDate,
                        }
                      : undefined,
                  updateUrl: apiUser.paddleSubscription.updateUrl,
                  cancelUrl: apiUser.paddleSubscription.cancelUrl,
              }
            : undefined,
        createdAt: new Date(apiUser.createdAt),
        updatedAt: new Date(apiUser.updatedAt),
    };
}

export function userDB2API(dbUser: IDBUser): IAPIUser {
    const dbGlobalOnboardingJson = dbUser.u_onboardings?.find(
        dbOnboardingJson => typeof dbOnboardingJson?.o_workspace_id !== 'number'
    );

    return {
        __typename: 'User',
        id: ID2GUID(ETables.users, dbUser.u_id),
        globalOnboarding: dbGlobalOnboardingJson
            ? onboardingDBJson2API(dbGlobalOnboardingJson)
            : undefined,
        workspaceData: dbUser.u_workspace_access_lists
            ? dbUser.u_workspace_access_lists
                  .sort(dbWorkspaceAccessListsComparator)
                  .reduce<
                      IAPIUser['workspaceData']
                  >((accumulator, [workspaceId, projectId, roleId]) => {
                      const apiWorkspaceId = ID2GUID(ETables.workspaces, workspaceId);

                      const accessListItem: IAPIAccessListItem = {
                          __typename: 'AccessListItem',
                          roleId: ID2GUID(ETables.roles, roleId),
                      };

                      if (typeof projectId === 'number') {
                          accessListItem.projectId = ID2GUID(ETables.projects, projectId);
                      }

                      let apiWorkspaceData = accumulator.find(
                          apiWorkspaceData => apiWorkspaceData.workspaceId === apiWorkspaceId
                      );

                      if (apiWorkspaceData) {
                          apiWorkspaceData.accessList.push(accessListItem);
                      } else {
                          apiWorkspaceData = {
                              __typename: 'WorkspaceData',
                              workspaceId: apiWorkspaceId,
                              accessList: [accessListItem],
                          };

                          const dbSuspension = dbUser.u_workspace_suspensions?.find(
                              dbSuspension => dbSuspension?.ssp_workspace_id === workspaceId
                          );

                          if (dbSuspension) {
                              apiWorkspaceData.suspension = suspensionDBJson2API(dbSuspension);
                          }

                          const dbOnboardingJson = dbUser.u_onboardings?.find(
                              dbOnboardingJson => dbOnboardingJson?.o_workspace_id === workspaceId
                          );

                          if (dbOnboardingJson) {
                              apiWorkspaceData.onboarding = onboardingDBJson2API(dbOnboardingJson);
                          }

                          accumulator.push(apiWorkspaceData);
                      }

                      return accumulator;
                  }, [])
            : [],
        invitedToWorkspaceIds: dbUser.u_invited_to_workspace_ids
            ? parseNumbersString(dbUser.u_invited_to_workspace_ids).map(id =>
                  ID2GUID(ETables.workspaces, id)
              )
            : [],
        email: dbUser.u_email,
        firstname: dbUser.u_firstname,
        lastname: dbUser.u_lastname,
        displayName: dbUser.u_display_name,
        calculatedName: dbUser.u_calculated_name,
        timezone: dbUser.u_timezone,
        status: dbUser.u_status,
        avatarS3Key: dbUser.u_avatar_s3_key,
        paddleSubscriptionId: dbUser.u_paddle_subscription_id,
        createdAt: dbUser.u_created_at.toISOString(),
        updatedAt: dbUser.u_updated_at.toISOString(),
    };
}

export function userJS2API(user: IUser): IAPIUser {
    return {
        __typename: 'User',
        id: ID2GUID(ETables.users, user.id),
        globalOnboarding: user.globalOnboarding
            ? onboardingJS2API(user.globalOnboarding)
            : undefined,
        workspaceData: Object.entries(user.workspaceDataMap).map(([workspaceId, workspaceData]) => {
            const apiWorkspaceData: IAPIWorkspaceData = {
                __typename: 'WorkspaceData',
                workspaceId: ID2GUID(ETables.workspaces, Number(workspaceId)),
                accessList: workspaceData.accessList.map(({ projectId, roleId }) => {
                    const accessListItem: IAPIAccessListItem = {
                        __typename: 'AccessListItem',
                        roleId: ID2GUID(ETables.roles, roleId),
                    };

                    if (typeof projectId === 'number') {
                        accessListItem.projectId = ID2GUID(ETables.projects, projectId);
                    }

                    return accessListItem;
                }),
            };

            if (workspaceData.suspension) {
                apiWorkspaceData.suspension = suspensionJS2API(user.id, workspaceData.suspension);
            }

            if (workspaceData.onboarding) {
                apiWorkspaceData.onboarding = onboardingJS2API(workspaceData.onboarding);
            }

            return apiWorkspaceData;
        }),
        invitedToWorkspaceIds: user.invitedToWorkspaceIds.map(id =>
            ID2GUID(ETables.workspaces, id)
        ),
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        displayName: user.displayName,
        calculatedName: user.calculatedName,
        timezone: user.timezone,
        status: user.status,
        avatarS3Key: user.avatarS3Key,
        paddleSubscriptionId: user.paddleSubscriptionId,
        paddleSubscription:
            user.paddleSubscription &&
            user.paddleSubscription.updateUrl &&
            user.paddleSubscription.cancelUrl
                ? {
                      __typename: 'PaddleSubscription',
                      id: user.paddleSubscription.id,
                      card: user.paddleSubscription.card
                          ? {
                                __typename: 'Card',
                                ...user.paddleSubscription.card,
                            }
                          : undefined,
                      updateUrl: user.paddleSubscription.updateUrl,
                      cancelUrl: user.paddleSubscription.cancelUrl,
                  }
                : undefined,
        createdAt: user.createdAt.toISOString(),
        updatedAt: user.updatedAt.toISOString(),
    };
}

export function userJS2APIInput(user: IUser): IAPIUserInput {
    return {
        id: ID2GUID(ETables.users, user.id),
        firstname: user.firstname,
        lastname: user.lastname,
        displayName: user.displayName,
        timezone: user.timezone,
        // There is no way we can add password input here.
        // To update password, user must manually provide old password.
        avatarS3Key: user.avatarS3Key,
    };
}

export function email2DisplayName(email: string): string {
    let displayName = email.split('@')[0];
    displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
    return displayName;
}

export function parsePendingTeamMembers(
    pendingTeamMembers: TPendingTeamMembers
): [string, string][] {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const value = Object.values(pendingTeamMembers).join(' ');
    const rawEmails = value.split(/[\s,]+/);

    return rawEmails.reduce<[string, string][]>((acc, email) => {
        email = email.trim();

        if (emailRegex.test(email) && acc.findIndex(([e]) => e === email) === -1) {
            acc.push([email, email2DisplayName(email)]);
        }

        return acc;
    }, []);
}

// utils
function dbWorkspaceAccessListsComparator(
    a: [number, number | null, number],
    b: [number, number | null, number]
) {
    if (a[1] === null && b[1] === null) return a[0] - b[0];

    if (a[1] !== null && b[1] !== null) return a[1] - b[1];

    return a[1] === null ? -1 : 1;
}

export function getIsWorkspacesAccessSame(
    workspaceDataMap1: TWorkspaceDataMap,
    workspaceDataMap2: TWorkspaceDataMap
) {
    const wsIds1 = Object.keys(workspaceDataMap1).map(Number).sort();
    const wsIds2 = Object.keys(workspaceDataMap2).map(Number).sort();

    if (!isEqual(wsIds1, wsIds2)) return false;

    for (const wsId of wsIds1) {
        const wsData1 = workspaceDataMap1[wsId];
        const wsData2 = workspaceDataMap2[wsId];

        if (
            (wsData1.suspension && !wsData2.suspension) ||
            (!wsData1.suspension && wsData2.suspension)
        ) {
            return false;
        }

        if (!isEqual(wsData1.accessList, wsData2.accessList)) return false;
    }

    return true;
}
