import { IAPIOnboarding, IDBOnboardingJson, IOnboarding } from '@ab-task/types';

export function onboardingDBJson2JS(dbOnboarding: IDBOnboardingJson): IOnboarding {
    return {
        name: dbOnboarding.o_name,
        step: dbOnboarding.o_step,
        updatedAt: new Date(dbOnboarding.o_updated_at),
    };
}

export function onboardingDBJson2API(dbOnboarding: IDBOnboardingJson): IAPIOnboarding {
    return {
        __typename: 'Onboarding',
        name: dbOnboarding.o_name,
        step: dbOnboarding.o_step,
        updatedAt: new Date(dbOnboarding.o_updated_at).toISOString(),
    };
}

export function onboardingAPI2JS(apiOnboarding: IAPIOnboarding): IOnboarding {
    return {
        name: apiOnboarding.name,
        step: apiOnboarding.step,
        updatedAt: new Date(apiOnboarding.updatedAt),
    };
}

export function onboardingJS2API(onboarding: IOnboarding): IAPIOnboarding {
    return {
        __typename: 'Onboarding',
        name: onboarding.name,
        step: onboarding.step,
        updatedAt: onboarding.updatedAt.toISOString(),
    };
}
