import {
    IAPITriggerMessage,
    IAPITriggerMessageBroadcast,
    IAPITriggerMessageInput,
    ITriggerMessage,
    ITriggerMessageInput,
} from '@ab-task/types';
import { GUID2ID, normalize } from './core';
import { messageAPI2Broadcast, messageAPI2JS } from './message';

export function triggerMessageAPI2JS(apiTriggerMessage: IAPITriggerMessage): ITriggerMessage {
    return {
        messageId: GUID2ID(apiTriggerMessage.messageId)[1],
        message: apiTriggerMessage.message ? messageAPI2JS(apiTriggerMessage.message) : undefined,
        head: normalize(apiTriggerMessage.head),
        anchor: normalize(apiTriggerMessage.anchor),
    };
}

export function triggerMessageAPI2Broadcast(
    apiTriggerMessage: IAPITriggerMessage
): IAPITriggerMessageBroadcast {
    return {
        messageId: apiTriggerMessage.messageId,
        message: apiTriggerMessage.message
            ? messageAPI2Broadcast(apiTriggerMessage.message)
            : undefined,
        head: normalize(apiTriggerMessage.head),
        anchor: normalize(apiTriggerMessage.anchor),
    };
}

export function triggerMessageInputAPI2JS(
    apiTriggerMessageInput: IAPITriggerMessageInput
): ITriggerMessageInput {
    return {
        messageId: GUID2ID(apiTriggerMessageInput.messageId)[1],
        head: normalize(apiTriggerMessageInput.head),
        anchor: normalize(apiTriggerMessageInput.anchor),
    };
}
