export type TSizeVariant = 'Small' | 'Normal' | 'Big';

export type TValidatorType =
    | 'required'
    | 'password'
    | 'email'
    | 'confirmation code'
    | 'positive float';

export type TValidateFn = (value: string) => boolean;

export interface IValidator {
    validate: TValidateFn;
    descriptionKey?: string;
}

export interface IInputValue {
    value: string;
    isValid?: boolean;
}

export interface IInputChangeHandler {
    (e: TInputChangeEvent): void;
}
export interface ITextAreaChangeHandler {
    (e: TTextAreaChangeEvent): void;
}

export type TInputChangeEvent = React.ChangeEvent<HTMLInputElement & { isValid?: boolean }>;
export type TTextAreaChangeEvent = React.ChangeEvent<HTMLTextAreaElement & { isValid?: boolean }>;
