import { isError } from '@ab-task/utils';
import { ApolloError } from '@apollo/client';
import { TGQLError, TAugmentedNetworkError } from '../types';

const isApolloError = (value: any): value is ApolloError =>
    isError(value) && value.name === 'ApolloError';

export const getWasErrorHandled = (error: any) => {
    if (!isApolloError(error)) return false;

    {
        const graphQLErrors = error.graphQLErrors as TGQLError[] | undefined;
        if (graphQLErrors?.find(error => !error.handled)) return false;
    }

    {
        const networkError = error?.networkError as TAugmentedNetworkError | undefined;

        if (!networkError?.handled) return false;

        if (networkError?.errors?.find(error => !error.handled)) return false;
    }

    return true;
};
