import {
    TransactionMethod,
    TaskStatus,
    TaskPriority,
    TaskLinkType,
    UserRole,
    UserStatus,
    DashboardType,
    ProjectStatus,
    TAPIFilter,
    TAPISort,
    IAPITasks,
    IPolicy,
    TAcl,
    IDBSuspension,
    IAPIMessage,
    IAPIAttachment,
    IAPICreatorAlignments,
    Maybe,
    OnboardingName,
    IDBOnboarding,
    IDBProject,
    IDBDocument,
    IDBTask,
    IDBTopic,
} from '.';

// core
export enum ETables {
    attachments = 'attachments',
    bills = 'bills',
    billsPayable = 'billsPayable',
    dashboards = 'dashboards',
    epics = 'epics',
    groups = 'groups',
    invites = 'invites',
    suspensions = 'suspensions',
    messages = 'messages',
    milestones = 'milestones',
    transactions = 'transactions',
    projects = 'projects',
    roles = 'roles',
    sessions = 'sessions',
    tasks = 'tasks',
    taskLinks = 'taskLinks',
    topics = 'topics',
    documents = 'documents',
    users = 'users',
    workspaces = 'workspaces',
    emojis = 'emojis',
}

export type TTable = keyof typeof ETables;

export interface IAdaptorWithCache<IN, OUT> {
    cache?: Map<IN, OUT>;
    (param: IN): OUT;
}

export type TDBID = [TTable, number];

export type TID = number | string;
export type TMBID = number | string | null | undefined;

export function isTID(value: any): value is TID {
    return typeof value === 'number' || typeof value === 'string';
}

export interface IOrigin {
    workspaceId: number;
    projectId?: number;
}

export type TAsyncFieldAdaptors<
    SOURCE extends Partial<Record<string, any>>,
    TARGET extends Partial<Record<string, any>>,
> = Partial<{
    [KEY in keyof TARGET]: (model: SOURCE) => Promise<TARGET[KEY]>;
}>;

// workspace
export interface IWorkspace {
    id: number;
    name: string;
    motto?: string;
    ownerId: number;
    createdAt: Date;
    updatedAt: Date;
    unreadCounters?: TUnreadCounters;
    policy?: IPolicy;
}

export type TUnreadCounters = Record<DashboardType | 'total', IUnreadCounter>;

export interface IUnreadCounter {
    unreads: number;
    hasMentions: boolean;
}

// user
export interface IUser {
    id: number;
    workspaceDataMap: TWorkspaceDataMap;
    globalOnboarding?: IOnboarding;
    invitedToWorkspaceIds: number[];
    password?: string;
    email: string;
    firstname?: string;
    lastname?: string;
    displayName: string;
    calculatedName: string;
    timezone: string;
    status: UserStatus;
    avatarS3Key?: string;
    paddleSubscriptionId?: number;
    paddleSubscription?: IPaddleSubscription;
    createdAt: Date;
    updatedAt: Date;
}

export type TWorkspaceDataMap = Record<number, IWorkspaceData>;

export interface IWorkspaceData {
    suspension?: ISuspension;
    accessList: IAccessListItem[];
    onboarding?: IOnboarding;
}

export interface IOnboarding {
    name: OnboardingName;
    step: number;
    updatedAt: Date;
}

export interface ISuspension {
    initiatorId: number;
    suspendedAt: Date;
}

export interface IDBSuspensionJson extends Omit<IDBSuspension, 'ssp_suspended_at'> {
    ssp_suspended_at: string;
}

export interface IDBOnboardingJson extends Omit<IDBOnboarding, 'o_updated_at'> {
    o_updated_at: string;
}

export interface IAccessListItem {
    projectId?: number;
    roleId: number;
}

export function isAccessListWithModels(
    accessListItems: IAccessListItem[] | IAccessListItemWithModels[]
): accessListItems is IAccessListItemWithModels[] {
    return accessListItems.length > 0 && accessListItems[0].hasOwnProperty('role');
}

export interface IAccessListItemWithModels {
    project?: IProject;
    role: IRole;
}

export interface IIdentity extends IUser {
    guid: string;
}

export interface IPaddleSubscription {
    id: number;
    card?: {
        type: string;
        lastFourDigits: string;
        expiryDate: string;
    };
    updateUrl?: string;
    cancelUrl?: string;
}

// invite
export interface IInvite {
    id: number;
    code: string;
    userId: number;
    inviterId?: number;
    workspaceId?: number;
    accessList?: IAccessListItem[];
    validUntil: Date;
    claimedAt?: Date;
}

// group
export interface IGroup {
    id: number;
    workspaceId: number;
    name: string;
    description?: string;
    memberIds: number[];
    policy?: IPolicy;
    createdAt: Date;
    updatedAt: Date;
    creatorAlignments?: ICreatorAlignments;
    messagedAt?: Date;
    unreads: number;
    hasMentions: boolean;
}

export interface IGroupInput {
    guid?: string;
    name?: string;
    description?: string;
}

// bill
export interface IBill {
    id: number;
    workspaceId: number;
    year: number;
    month: number;
    duration: number; // minutes
    requestsCount: number;
    storageUsage: number;
    storagePcplRequestsCount: number;
    storageOtherRequestsCount: number;
    storageDataTransfer: number;
    total: number;
    amountToCharge?: number;
}

export interface IBillExtended extends IBill {
    userId: number;
    paddleSubscriptionId?: number;
}

export interface IBillPayable extends Omit<IBill, 'workspaceId'> {
    billIds: IBill['id'][];
}

// session
export interface ISession {
    id: number;
    userId: number;
    secret: string;
    createdAt: Date;
}

// access
export interface IRole {
    id: number;
    name: UserRole;
    policy: IPolicy;
}

// topic
export interface ITopic {
    id: number;
    workspaceId: number;
    projectId?: number;
    name?: string;
    createdAt: Date;
    updatedAt: Date;
    creatorAlignments?: ICreatorAlignments;
    messagedAt?: Date;
    policy?: IPolicy;
    unreads: number;
    hasMentions: boolean;
    triggerMessage?: ITriggerMessage;
}

// document
export interface IDocument {
    id: number;
    workspaceId: number;
    projectId?: number;
    name?: string;
    createdAt: Date;
    updatedAt: Date;
    messagedAt?: Date;
    policy?: IPolicy;
    unreads: number;
    hasMentions: boolean;
    triggerMessage?: ITriggerMessage;
}

export interface IDocumentInput {
    name?: string;
}

// project
export type DBProjectStatus = 'ACTIVE' | 'COMPLETED';

export interface IProject {
    id: number;
    workspaceId: number;
    name: string;
    status: ProjectStatus;
    creatorId: number;
    createdAt: Date;
    updatedAt: Date;
    creatorAlignments?: ICreatorAlignments;
    messagedAt?: Date;
    policy?: IPolicy;
    unreads: number;
    hasMentions: boolean;
    triggerMessage?: ITriggerMessage;
}

export interface IProjectInput {
    name?: string;
}

// epic
export interface IEpic {
    id: number;
    workspaceId: number;
    projectId: number;
    milestoneId?: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    policy?: IPolicy;
}

export interface IEpicInput {
    name?: string;
}

// task
export interface ITask {
    id: number;
    workspaceId: number;
    projectId?: number;
    milestoneId?: number;
    epicId?: number;
    name: string;
    status: TaskStatus;
    estimate?: number;
    priority?: TaskPriority;
    links: ITaskLink[];
    creatorId: number;
    createdAt: Date;
    updatedAt: Date;
    creatorAlignments?: ICreatorAlignments;
    messagedAt?: Date;
    ownerId?: number;
    policy?: IPolicy;
    unreads: number;
    hasMentions: boolean;
    acl?: TAcl<'task'>;
    triggerMessage?: ITriggerMessage;
}

export interface ITaskLink {
    fromId: number;
    toId: number;
    linkType: TaskLinkType;
    name: string;
    status: TaskStatus;
    resolved: boolean;
}

export type TExtendedLinkType = TaskLinkType | 'BLOCKED_BY' | 'CAUSED_BY';

export interface ITaskInput {
    name?: string;
}

export type TCacheTasks = Omit<IAPITasks, 'tasks'> & {
    tasks: Array<{ __ref: string }>;
};

// emoji
export interface IEmoji {
    id: number;
    imageBase64: string;
    shortname: string;
    unicode: string;
    recent?: boolean;
}

// message
export interface IMessage {
    id: number;

    content?: string;
    version: number;
    isFirst: boolean;
    isLast: boolean;

    creatorId: number;
    authorIds: number[];
    createdAt: Date;
    updatedAt: Date;

    origin?: IOrigin;
    policy?: IPolicy;

    workspaceId?: number;
    groupId?: number;
    topicId?: number;
    documentId?: number;
    projectId?: number;
    milestoneId?: number;
    epicId?: number;
    taskId?: number;

    reactions: IMessageReaction[];
    watches: IMessageWatch[];
    followUps: IMessageFollowUp[];

    // client-side fields
    isBig?: boolean;
    isReadonly?: boolean;
    attachmentS3Keys?: Array<string>;
}

export interface IMessageWatch {
    userId: number;
    watchedAt: Date;
}

export interface IMessageReaction {
    userId: number;
    emojiId: number;
    reactedAt: Date;
}

export type TFollowUpType = 'PROJECTS' | 'TASKS' | 'TOPICS' | 'DOCUMENTS';

export interface IMessageFollowUp {
    itemType: TFollowUpType;
    itemId: number;
    anchor?: number;
    head?: number;
}

export type TRangedMessageFollowUp = Required<IMessageFollowUp>;

export function isRangedMessageFollowUp(
    followUp?: Maybe<IMessageFollowUp>
): followUp is TRangedMessageFollowUp {
    return Boolean(
        followUp && typeof followUp?.anchor === 'number' && typeof followUp.head === 'number'
    );
}

export type ITriggerMessageInput = {
    messageId: TID;
    anchor?: number;
    head?: number;
};

export function isAPIMessage(message: any): message is IAPIMessage {
    return (
        message.__typename === 'Message' &&
        typeof message.id === 'string' &&
        Array.isArray(message.authorIds) &&
        typeof message.createdAt === 'string' &&
        typeof message.creatorId === 'string' &&
        typeof message.isFirst === 'boolean' &&
        typeof message.isLast === 'boolean' &&
        Array.isArray(message.reactions) &&
        typeof message.updatedAt === 'string' &&
        typeof message.version === 'number' &&
        Array.isArray(message.watches) &&
        Array.isArray(message.followUps)
    );
}

// unread
export interface IUnread {
    messageId: number;
    messageCreatedAt: Date;
    messageUpdatedAt: Date;
    wasMentionedSinceWatched: boolean;
}

// attachments
export interface IAttachment {
    messageId: number;
    filename: string;
    contentType: string;
    s3Key: string;
    createdAt: Date;

    // client-side fields
    progress?: number;
}

export function isAPIAttachment(attachment: Partial<IAPIAttachment>): attachment is IAPIAttachment {
    return (
        attachment.__typename === 'Attachment' &&
        typeof attachment.contentType === 'string' &&
        typeof attachment.createdAt === 'string' &&
        typeof attachment.filename === 'string' &&
        typeof attachment.messageId === 'string' &&
        typeof attachment.s3Key === 'string'
    );
}

// transactions
export interface ITransaction {
    id: number;
    userId: number;
    method: TransactionMethod;
    receiptUrl: string;
    total: number;
    processedAt: Date;
}

// dashboard
export interface IDashboard {
    id: number;
    key?: string;
    workspaceId: number;
    ownerId: number;
    type: DashboardType;
    name?: string;
    config?: IDashboardConfig;
    favorite?: boolean;
    order: number;
    unreads: number;
    hasMentions: boolean;

    // client-side fields
    expanded?: boolean;
}

export interface IDashboardConfig {
    filter?: TAPIFilter;
    sort?: TAPISort[];
    view?: ITasksTable | ITasksKanban;
    navigationConfig?: IDashboardConfig;
}

export type TViewType = 'table' | 'kanban';

interface IItemsView {
    name: TViewType;
}

export interface ITasksTable extends IItemsView {
    name: 'table';
}

export interface ITasksKanban extends IItemsView {
    name: 'kanban';
    splitBy: TTasksKanbanSplitBy;
}

export type TTasksKanbanSplitBy = keyof Pick<ITask, 'status' | 'priority' | 'ownerId'>;

export function isTasksTable(view?: ITasksTable | ITasksKanban): view is ITasksTable {
    return view?.name === 'table';
}

export function isTasksKanban(view?: ITasksTable | ITasksKanban): view is ITasksKanban {
    return view?.name === 'kanban';
}

export type TCacheDashboards = Array<{ __ref: string }>;

export interface ICreatorAlignments {
    left: number[];
    right: number[];
}

export function isApiCreatorAlignments(
    creatorAlignments?: Maybe<ICreatorAlignments> | Maybe<IAPICreatorAlignments>
): creatorAlignments is IAPICreatorAlignments {
    return Boolean(
        creatorAlignments &&
            (creatorAlignments as IAPICreatorAlignments)['__typename'] === 'CreatorAlignments' &&
            (typeof creatorAlignments.left[0] === 'string' ||
                typeof creatorAlignments.right[0] === 'string')
    );
}

export function isJsCreatorAlignments(
    creatorAlignments?: Maybe<ICreatorAlignments> | Maybe<IAPICreatorAlignments>
): creatorAlignments is ICreatorAlignments {
    return Boolean(
        creatorAlignments &&
            !creatorAlignments.hasOwnProperty('__typename') &&
            (typeof creatorAlignments.left[0] === 'number' ||
                typeof creatorAlignments.right[0] === 'number')
    );
}

export interface ITriggerMessage {
    messageId: number;
    message?: IMessage;
    anchor?: number;
    head?: number;
}

export function isDBProject(
    model: IDBProject | IDBTopic | IDBTask | IDBDocument
): model is IDBProject {
    return typeof (model as IDBProject).p_id === 'number';
}

export function isDBTopic(model: IDBProject | IDBTopic | IDBTask | IDBDocument): model is IDBTopic {
    return typeof (model as IDBTopic).t_id === 'number';
}

export function isDBTask(model: IDBProject | IDBTopic | IDBTask | IDBDocument): model is IDBTask {
    return typeof (model as IDBTask).tsk_id === 'number';
}

export function isDBDocument(
    model: IDBProject | IDBTopic | IDBTask | IDBDocument
): model is IDBDocument {
    return typeof (model as IDBDocument).d_id === 'number';
}
