import { ELSKeys } from '@ab-task/data';
import {
    EConnectionState,
    ESocketState,
    ESubscriptionsState,
    INotification,
    TPendingTeamMembers,
    TWorkspaceContexts,
} from '@ab-task/types';
import { makeVar } from '@apollo/client';
import { makePersistentVar } from './helpers';

export const rvCurrentWorkspaceId = makePersistentVar<number | undefined>(
    undefined,
    ELSKeys.CurrentWorkspaceId
);

export const rvWorkspaceContexts = makePersistentVar<TWorkspaceContexts>(
    {},
    ELSKeys.WorkspaceContexts
);

export const rvConnectionState = makeVar<EConnectionState>(EConnectionState.Online);
export const rvSocketState = makeVar<ESocketState>(ESocketState.Closed);
export const rvSubscriptionsState = makeVar<ESubscriptionsState>(ESubscriptionsState.Inactive);
export const rvNotifications = makeVar<INotification[]>([]);

export const rvExpandedDashboardIds = makePersistentVar<string[]>([], ELSKeys.ExpandedDashboardIds);

export const rvPendingTeamMembers = makeVar<TPendingTeamMembers>({
    ADMIN: '',
    MEMBER: '',
});
