import { ApolloClient } from '@apollo/client';
import { isEqual } from 'lodash';
import { getOperationInfo } from '../utils/getOperationInfo';
import { activeQueryStoreKeys } from '../utils/trackQuery';
import rootLogger from './logger';
import { TInvalidateQueriesInputs } from '../types';

/*
    Run this line in browser dev console and reload the page to enable logging
    localStorage.setItem('debug', 'apollo:cache:invalidate-queries');
*/
const logger = rootLogger.extend('invalidate-queries');

export async function invalidateQueries(
    client: ApolloClient<object>,
    inputs: TInvalidateQueriesInputs
) {
    const operationNames = new Set<string>();

    // 1. Evict cache records for inactive (unmounted) queries
    inputs.forEach(input => {
        const query = Array.isArray(input) ? input[0] : input;
        const args = Array.isArray(input) ? input[1] : undefined;
        const { operationNames: queryOperationNames, fieldNames } = getOperationInfo(query);

        queryOperationNames.forEach(name => operationNames.add(name));

        if (fieldNames.length > 0) {
            const cacheSnapshot = client.cache.extract();

            const rootQuery =
                cacheSnapshot && typeof cacheSnapshot === 'object'
                    ? (cacheSnapshot as Record<string, any>)['ROOT_QUERY']
                    : null;

            const storeKeys = Object.keys(rootQuery);

            fieldNames.forEach(fieldName => {
                storeKeys
                    .filter(storeKey => {
                        const [storeFieldName, storeFieldArguments] =
                            storeKey2FieldNameAndArguments(storeKey);

                        /*
                         * Filter out keys that correspond to different fields or fields that correspond to different arguments
                         */
                        if (
                            storeFieldName !== fieldName ||
                            (args && !isEqual(args, storeFieldArguments))
                        ) {
                            return false;
                        }

                        for (const activeQueryStoreKey of activeQueryStoreKeys) {
                            const [activeQueryFieldName, activeQueryFieldArguments] =
                                storeKey2FieldNameAndArguments(activeQueryStoreKey);

                            if (
                                storeFieldName === activeQueryFieldName &&
                                isEqual(storeFieldArguments, activeQueryFieldArguments)
                            ) {
                                /*
                                 * Filter out keys that correspond to active queries because they will be refetched due to refetchQueries call below and we don't want to evict their cache records
                                 */
                                return false;
                            }
                        }

                        return true;
                    })
                    .forEach(storeKey => {
                        logger('Evicting cache record', storeKey);

                        client.cache.evict({
                            id: 'ROOT_QUERY',
                            fieldName: storeKey,
                        });
                    });
            });
        }
    });

    // 2. Refetch active (mounted) queries
    logger('Refeching active queries', operationNames);
    await client.refetchQueries({
        include: [...operationNames],
    });

    // 3. Garbage collect
    client.cache.gc();
}

// utils
type TActiveQueryMatch = [string, string, string | undefined];

function storeKey2FieldNameAndArguments(
    storeKey: string
): [string | undefined, Record<string, any> | undefined] {
    const regex = /^([^(:]+)(?:(?::|\()({.+})\)?)?$/;
    const match = storeKey.match(regex);

    if (!match) {
        console.warn(`Was not able to parse storeKey`);
        return [undefined, undefined];
    }

    const [, fieldName, fieldArgumentsString] = match as TActiveQueryMatch;
    const fieldArguments = fieldArgumentsString ? JSON.parse(fieldArgumentsString) : undefined;

    return [fieldName, fieldArguments];
}
