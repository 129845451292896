export interface IObject<T = any> {
    [key: string]: T | undefined;
}

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export type NestedKeyOf<T extends object, ExcludedKeys extends string | number = never> = {
    [Key in keyof T & (string | number)]: Key extends ExcludedKeys
        ? never
        : Exclude<T[Key], undefined | null> extends object
          ? Exclude<T[Key], undefined | null> extends Array<any>
              ? `${Key}`
              : `${Key}` | `${Key}.${NestedKeyOf<Exclude<T[Key], undefined | null>, ExcludedKeys>}`
          : `${Key}`;
}[keyof T & (string | number)];

export type GraphQLNestedKeyOf<T extends object> = NestedKeyOf<T, '__typename'>;

export type Writable<T> = { -readonly [P in keyof T]: T[P] };

type IsObject<T> = T extends object ? (T extends Function ? never : T) : never;

export type RecursiveReplace<T, V> = {
    [P in keyof T]: IsObject<T[P]> extends never ? V : RecursiveReplace<T[P], V>;
};

export type ObjectFromType<T> = { [P in keyof T]: T[P] };

export type OptionalFields<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type StaticFunctionNames<T> = {
    [K in keyof T]: T[K] extends Function ? K : never;
}[keyof T];

export interface IExist {
    mustExist: true;
}

export interface IFilledArray<T> extends Array<T> {
    0: T;
}

export function isStringArray(arr: (string | number)[]): arr is string[] {
    return typeof arr[0] === 'string';
}

export function isNumberArray(arr: (string | number)[]): arr is number[] {
    return typeof arr[0] === 'number';
}
