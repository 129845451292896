import { getModelACID, messageContent2Doc } from '@ab-task/utils';
import { type ApolloCache, type Reference, type StoreObject } from '@apollo/client';
import editorSchema from '@ab-task/prosemirror';
import { ETables, IAPIMessage, isAPIMessage, isTID, Maybe, TID } from '@ab-task/types';
import {
    IAPIIdentityQuery,
    IAPIMessageClientFieldsFragment,
    IdentityDocument,
    MessageClientFieldsFragmentDoc,
    MessageFieldsFragmentDoc,
} from '@ab-task/apollo';
import { BIG_MESSAGE_THRESHOLD } from '@ab-task/data';

interface IupdateMessageClientFieldsInCachePayload {
    message: IAPIMessage | StoreObject | TID | Reference;
    isIncoming?: Maybe<boolean>;
}

export default function updateMessageClientFieldsInCache(
    payload: IupdateMessageClientFieldsInCachePayload,
    cache: ApolloCache<object>
): void {
    const { message, isIncoming } = payload;

    let messageAcid: string | undefined;
    if (isAPIMessage(message)) {
        messageAcid = getModelACID(message.id, ETables.messages);
    } else if (isTID(message)) {
        messageAcid = getModelACID(message, ETables.messages);
    } else {
        messageAcid = cache.identify(message);
    }

    let isBig = false;
    let isReadonly = true;
    let attachmentS3Keys: string[] = [];

    if (typeof messageAcid === 'string') {
        const apiMessage = cache.readFragment<IAPIMessage>({
            id: messageAcid,
            fragmentName: 'MessageFields',
            fragment: MessageFieldsFragmentDoc,
        });

        if (apiMessage) {
            if (typeof apiMessage.content === 'string') {
                const doc = messageContent2Doc(editorSchema, apiMessage.content);
                const newLength = doc ? doc.textContent.length : 0;
                isBig = newLength > BIG_MESSAGE_THRESHOLD;
            }

            {
                const apiIdentity = cache.readQuery<IAPIIdentityQuery>({
                    query: IdentityDocument,
                });

                if (
                    typeof apiMessage.isFirst === 'boolean' &&
                    typeof apiMessage.creatorId === 'string'
                ) {
                    isReadonly =
                        !apiMessage.isFirst &&
                        (!apiIdentity?.identity ||
                            apiIdentity.identity.id !== apiMessage.creatorId);
                }
            }

            if (apiMessage?.attachmentS3Keys) {
                attachmentS3Keys = apiMessage.attachmentS3Keys;
            }
        }
    }

    cache.writeFragment<IAPIMessageClientFieldsFragment>({
        id: messageAcid,
        fragment: MessageClientFieldsFragmentDoc,
        data: {
            __typename: 'Message',
            isBig,
            isReadonly,
            attachmentS3Keys,
            isIncoming,
        },
    });
}
