import { IDBTaskLink, IAPITaskLink, ITaskLink, IAPITaskLinkBroadcast } from '@ab-task/types';
import { ID2GUID, GUID2ID } from './core';
import { getIsTaskLinkResolved } from './task';

export function taskLinkDB2API(taskId: number, dbTaskLink: IDBTaskLink): IAPITaskLink {
    return {
        __typename: 'TaskLink',
        id: ID2GUID('tasks', taskId) + `:${dbTaskLink.tl_task_from_id}-${dbTaskLink.tl_task_to_id}`,
        toId: ID2GUID('tasks', dbTaskLink.tl_task_to_id),
        fromId: ID2GUID('tasks', dbTaskLink.tl_task_from_id),
        linkType: dbTaskLink.tl_link_type,
        name: dbTaskLink.tl_linked_task_name,
        status: dbTaskLink.tl_linked_task_status,
    };
}

export function taskLinkDB2JS(dbTaskLink: IDBTaskLink): ITaskLink {
    return {
        fromId: dbTaskLink.tl_task_from_id,
        toId: dbTaskLink.tl_task_to_id,
        linkType: dbTaskLink.tl_link_type,
        name: dbTaskLink.tl_linked_task_name,
        status: dbTaskLink.tl_linked_task_status,
        resolved: getIsTaskLinkResolved(dbTaskLink.tl_link_type, dbTaskLink.tl_linked_task_status),
    };
}

export function taskLinkAPI2JS(apiTaskLink: IAPITaskLink): ITaskLink {
    return {
        fromId: GUID2ID(apiTaskLink.fromId)[1],
        toId: GUID2ID(apiTaskLink.toId)[1],
        linkType: apiTaskLink.linkType,
        name: apiTaskLink.name,
        status: apiTaskLink.status,
        resolved: getIsTaskLinkResolved(apiTaskLink.linkType, apiTaskLink.status),
    };
}

export function taskLinkAPI2Broadcast(apiTaskLink: IAPITaskLink): IAPITaskLinkBroadcast {
    return {
        id: apiTaskLink.id,
        fromId: apiTaskLink.fromId,
        toId: apiTaskLink.toId,
        linkType: apiTaskLink.linkType,
        name: apiTaskLink.name,
        status: apiTaskLink.status,
    };
}
