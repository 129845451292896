import { TBackendErrorCode, TFrontendErrorCode } from '@ab-task/errors';

export const IGNORED_ERRORS_LIST: (TFrontendErrorCode | TBackendErrorCode)[] = [
    'OPERATION_CANCELLED',
];

export const BYPASSED_ERRORS_LIST: (TFrontendErrorCode | TBackendErrorCode)[] = [
    'USER_EXISTS',
    'WRONG_PASSWORD',
    'USER_BANNED',
    'USER_NOT_FOUND',
    'USER_NO_PASSWORD',
    'STEPS_REJECTED',
];

export const BYPASSED_MUTATIONS_LIST = ['RemoveWorkspace'];
