import { CoreError } from '@ab-task/errors';
import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { getCache, typeDefs } from './cache';
import {
    getAuthLink,
    getClientIdLink,
    getAppSyncLink,
    getWorkspaceIdLink,
    getMutationsControllerLink,
    getErrorLink,
} from './links';
import { TApolloClientConfig, isNodeConfig } from './types';
import { setContext } from '@apollo/client/link/context';

export { ApolloError } from '@apollo/client';

export { ETypeNames } from './types';
export type { INodeConfig, IBrowserConfig, TGQLError, TInvalidateQueriesInputs } from './types';
export * from './graphql';
export { invalidateQueries } from './cache';
export * from './cache/reactive-variables';
export { useTrackQuery } from './utils/trackQuery';
export { useTrackSubscription } from './utils/trackSubscription';
export * from './utils/errorUtils';
export { updateMessageClientFieldsInCache } from './cache/helpers';

let client: ApolloClient<NormalizedCacheObject> | undefined;
let checksum: string;

export async function getApolloClient(config: TApolloClientConfig) {
    if (client) {
        const newChecksum = `${config.clientId}:${isNodeConfig(config) ? config.appToken : 'n/a'}`;

        if (checksum === newChecksum) {
            return client;
        } else {
            checksum = newChecksum;
        }
    }

    switch (config.environment) {
        case 'node': {
            const cache = await getCache(config);
            const authLink = getAuthLink(config);
            const clientIdLink = getClientIdLink(config);
            const workspaceIdLink = getWorkspaceIdLink(config);
            const appSyncLink = getAppSyncLink(config);

            client = new ApolloClient({
                name: config.environment,
                link: ApolloLink.from([
                    setContext(() => ({ client })),
                    authLink,
                    clientIdLink,
                    workspaceIdLink,
                    appSyncLink,
                ]),
                cache,
                assumeImmutableResults: true,
            });

            break;
        }

        case 'browser': {
            const cache = await getCache(config);
            const errorLink = getErrorLink(config);
            const authLink = getAuthLink(config);
            const clientIdLink = getClientIdLink(config);
            const workspaceIdLink = getWorkspaceIdLink(config);
            const mutationsControllerLink = getMutationsControllerLink(config);
            const appSyncLink = getAppSyncLink(config);

            client = new ApolloClient({
                name: config.environment,
                link: ApolloLink.from([
                    setContext(() => ({ client })),
                    errorLink,
                    authLink,
                    clientIdLink,
                    workspaceIdLink,
                    mutationsControllerLink,
                    appSyncLink,
                ]),
                cache,
                typeDefs,
                assumeImmutableResults: true,
            });

            break;
        }

        default: {
            throw CoreError.CONFIGURATION_ERROR({
                info: `Unknown environment passed in apollo client config`,
            });
        }
    }

    return client;
}
