import { unbase64, base64, camelCaseToUnderscores, stableStringify } from '..';
import { CoreError, FrontendError } from '@ab-task/errors';
import {
    ETables,
    IAdaptorWithCache,
    TaskPriority,
    TAsyncFieldAdaptors,
    TCondition,
    TDBID,
    TID,
    TMBID,
    TTable,
} from '@ab-task/types';
import { ETypeNames } from '@ab-task/apollo';
import { t } from '@ab-task/internationalization';

export function IDPair2GUID(table: TTable, a: number, b: number) {
    return ID2GUID(table, idPair2UniqueId(a, b));
}

export function ID2GUID(table: TTable, id: number): string {
    return base64([table, id].join(':'));
}

export function GUID2ID(guid: string): TDBID {
    const unbasedGUID = unbase64(guid);
    const delimiterPos = unbasedGUID.indexOf(':');

    const table = unbasedGUID.substring(0, delimiterPos);
    if (ETables[table as TTable] === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({ table, info: 'Extracting data from GUID failed' });
    }

    const id = Number(unbasedGUID.substring(delimiterPos + 1));
    if (id < 0 || Math.floor(id) !== id || id === Infinity) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: 'Extracting data from GUID failed, id is not a natural number',
        });
    }

    return [
        unbasedGUID.substring(0, delimiterPos) as TTable,
        Number(unbasedGUID.substring(delimiterPos + 1)),
    ];
}

export function ID2Filter(id: TID) {
    return typeof id === 'string' ? { equal: id } : { equal: id };
}

export function IDs2Filter(ids: readonly TID[]) {
    return typeof ids[0] === 'string' ? { include: ids as string[] } : { include: ids as number[] };
}

/**
 * Converts GUID to Apollo Cache ID
 */
export function GUID2ACID(guid: string) {
    const typeName = GUID2ACTN(guid);
    return computeACID(typeName, guid);
}

export function computeACID(typename: ETypeNames, tail: string) {
    return `${typename}:${tail}`;
}

/**
 * Converts fields with values to Apollo Cache ID (useful for custom cache id-s)
 */
export function Fields2ACID(typename: ETypeNames, fields: Record<string, any>) {
    return computeACID(typename, stableStringify(fields));
}

/**
 * Converts GUID to Apollo Cache Type Name
 */
export function GUID2ACTN(guid: string) {
    const [table] = GUID2ID(guid);
    return table2ACTN(table);
}

/**
 * Converts Table to Apollo Cache Type Name
 */
export function table2ACTN(table: TTable) {
    switch (table) {
        case ETables.workspaces:
            return ETypeNames.Workspace;

        case ETables.users:
            return ETypeNames.User;

        case ETables.topics:
            return ETypeNames.Topic;

        case ETables.documents:
            return ETypeNames.Document;

        case ETables.tasks:
            return ETypeNames.Task;

        case ETables.projects:
            return ETypeNames.Project;

        case ETables.messages:
            return ETypeNames.Message;

        case ETables.groups:
            return ETypeNames.Group;

        case ETables.epics:
            return ETypeNames.Epic;

        case ETables.emojis:
            return ETypeNames.Emoji;

        case ETables.dashboards:
            return ETypeNames.Dashboard;

        default:
            throw CoreError.TYPE_ADAPTOR_FAILED({
                table,
                info: 'table2ACTN to ACTN failed',
            });
    }
}

export function table2ChannelCol(table: TTable) {
    switch (table) {
        case ETables.groups:
            return 'm_group_id';

        case ETables.workspaces:
            return 'm_workspace_id';

        case ETables.topics:
            return 'm_topic_id';

        case ETables.documents:
            return 'm_document_id';

        case ETables.projects:
            return 'm_project_id';

        case ETables.milestones:
            return 'm_milestone_id';

        case ETables.epics:
            return 'm_epic_id';

        case ETables.tasks:
            return 'm_task_id';

        default:
            throw CoreError.TYPE_ADAPTOR_FAILED({
                info: `Channel column in "messages" table can't be found for "${table}"`,
            });
    }
}

export function getModelID(modelId: TID, expectedTable?: TTable): number;
export function getModelID(modelId?: TMBID, expectedTable?: TTable): number | null;
export function getModelID(modelId?: TMBID, expectedTable?: TTable) {
    if (modelId === undefined || modelId === null) {
        return null;
    }

    if (typeof modelId === 'number') {
        return modelId;
    }

    const [table, id] = GUID2ID(modelId);
    if (expectedTable && table !== expectedTable) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            expectedTable,
            table,
            info: `Guid is expected to contain ${expectedTable} table, but got ${table}`,
        });
    }

    return id;
}

export function getModelIDs(modelIds: ReadonlyArray<TID> = [], expectedTable: TTable) {
    let ids = modelIds.map(modelId => getModelID(modelId, expectedTable));
    ids = [...new Set(ids)].sort((a, b) => a - b);

    return ids;
}

export function getModelGUID(modelId: TID, expectedTable?: TTable): string;
export function getModelGUID(modelId?: TMBID, expectedTable?: TTable): string | null;
export function getModelGUID(modelId?: TMBID, expectedTable?: TTable) {
    if (modelId === undefined || modelId === null) {
        return null;
    }

    if (typeof modelId === 'string') {
        return modelId;
    }

    if (expectedTable === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Can't get GUID for id(${modelId}): expectedTable is undefined`,
        });
    }

    return ID2GUID(expectedTable, modelId);
}

export function getModelACID(modelId: TID, expectedTable?: TTable): string;
export function getModelACID(modelId?: TMBID, expectedTable?: TTable): string | null;
export function getModelACID(modelId?: TMBID, expectedTable?: TTable) {
    const guid = getModelGUID(modelId, expectedTable);
    return typeof guid === 'string' ? GUID2ACID(guid) : null;
}

export function getModelGUIDs(modelIds: ReadonlyArray<TID> = [], expectedTable: TTable) {
    let guids = modelIds.map(modelId => getModelID(modelId, expectedTable));
    guids = [...new Set(guids)];

    return guids;
}

export function normalize(v: Date): Date;
export function normalize(v?: Date | null): Date | undefined;
export function normalize(v?: TaskPriority | null): TaskPriority | undefined;
export function normalize(v: number): number;
export function normalize(v?: number | null): number | undefined;
export function normalize(v: string): string;
export function normalize(v?: string | null): string | undefined;
export function normalize(v: boolean): boolean;
export function normalize(v?: boolean | null): boolean | undefined;
export function normalize<T>(v: Array<T>): Array<T>;
export function normalize<T>(v?: Array<T> | null): Array<T> | undefined;
export function normalize<T extends Object>(v: T): T;
export function normalize<T extends Object>(v?: T | null): T | undefined;
export function normalize(v?: any) {
    if (v !== null) {
        return v;
    }

    return undefined;
}

export function ifValue<T1, T2>(v: T1 | null, fn: (v: T1) => T2): T2 | null {
    if (v === null) {
        return null;
    }

    return fn(v);
}

export function ifValueNormalized<T1, T2>(v: T1 | null, fn: (v: T1) => T2): T2 | undefined {
    if (v === null) {
        return undefined;
    }

    return fn(v);
}

interface ICheckWithCache<M extends Record<string, any>> {
    cache: Record<string, boolean>;
    (model: M): Promise<boolean>;
}

export function getCheckWithCache<M extends Record<string, any>>(
    model2Key: (model: M) => string,
    check: (model: M) => Promise<boolean>
) {
    const checkWithCache: ICheckWithCache<M> = async model => {
        const key = model2Key(model);
        const { cache } = checkWithCache;

        if (cache[key] === undefined) {
            cache[key] = await check(model);
        }

        return cache[key];
    };

    checkWithCache.cache = {};

    return checkWithCache;
}

export function key2DBColumn(
    key: string,
    defaultPrefix: string,
    keysByPrefix?: Record<string, string[]>,
    aliases?: Record<string, string>
) {
    let prefix = defaultPrefix;

    if (aliases) {
        const alias = aliases[key];
        if (alias) {
            return alias;
        }
    }

    if (keysByPrefix) {
        for (const [prefixOption, keys] of Object.entries(keysByPrefix)) {
            if (keys.includes(key)) {
                prefix = prefixOption;
                break;
            }
        }
    }

    return prefix.length > 0
        ? prefix + '_' + camelCaseToUnderscores(key)
        : camelCaseToUnderscores(key);
}

export const filterCondition2Human: IAdaptorWithCache<TCondition, string> = condition => {
    if (!filterCondition2Human.cache) {
        filterCondition2Human.cache = new Map([
            ['include', '='],
            ['exclude', '!='],
            ['like', t('contains') + ':'],
            ['startsWith', t('starts with') + ':'],
            ['isNull', t('not set')],
            ['equal', '='],
            ['moreThan', '>'],
            ['lessThan', '<'],
        ]);
    }

    const keysMap = filterCondition2Human.cache;
    const label = keysMap.get(condition);

    if (label === undefined) {
        throw CoreError.TYPE_ADAPTOR_FAILED({
            info: `Expected label for condition "${condition}" but got undefined`,
        });
    }

    return label;
};

/**
 * Converts JS Date to MySQL standart format.
 * For example: "2021-11-10T11:42:02.000Z" -> "2021-11-10 11:42:02"
 */
export function dateJS2MySQL(date: Date | string): string {
    date = new Date(date);

    if (isNaN(date.getTime())) {
        throw FrontendError.PAYLOAD_MALFORMED({
            info: 'Expected a valid date as input but got Invalid Date ',
        });
    }

    return date.toISOString().slice(0, 19).replace('T', ' ');
}

export function idPair2UniqueId(a: number, b: number) {
    return a < b
        ? Math.floor(((a + b) * (a + b + 1)) / 2 + b)
        : Math.floor(((a + b) * (a + b + 1)) / 2 + a) + (a === b ? 0 : 1);
}

export function withFieldAdaptors<
    TSource extends Partial<Record<string, any>>,
    TTarget extends Partial<Record<string, any>>,
>(
    baseAdaptor: (source: TSource) => TTarget,
    fieldAdaptors: TAsyncFieldAdaptors<TSource, TTarget>
): (source: TSource) => Promise<TTarget> {
    return async source => {
        const keys = Object.keys(fieldAdaptors) as Array<keyof TTarget>;
        const values = await Promise.all(keys.map(key => fieldAdaptors[key]?.(source)));

        return keys.reduce((accumulator, key, i) => {
            if (values[i]) {
                accumulator[key] = values[i];
            }

            return accumulator;
        }, baseAdaptor(source));
    };
}
