import { GUID2ACID, GUID2ACTN } from '@ab-task/utils';
import { FieldFunctionOptions, TypePolicies } from '@apollo/client';
import {
    IAPIAttachment,
    IAPIMessage,
    IAPIQueryTasksArgs,
    Maybe,
    TCacheDashboards,
    TCacheTasks,
} from '@ab-task/types';
import { rvExpandedDashboardIds } from './reactive-variables';
import { AttachmentClientFieldsFragmentDoc, IAPIAttachmentClientFieldsFragment } from '../graphql';
import { ETypeNames } from '../types';
import { updateMessageClientFieldsInCache } from './helpers';

export const typePolicies: TypePolicies = {
    Query: {
        fields: {
            node: {
                read(existing, { args, toReference }) {
                    if (!args) return;

                    const { id: guid } = args;

                    return toReference({
                        __typename: GUID2ACTN(guid),
                        id: guid,
                    });
                },
            },
            dashboards: {
                merge(existing: Maybe<TCacheDashboards>, incoming: Maybe<TCacheDashboards>) {
                    return incoming;
                },
            },
            tasks: {
                keyArgs: (args, context) => {
                    if (!args) return false;

                    if (args.pagination?.type === 'SCROLL') {
                        return ['filter', 'sort', 'pagination', ['type']];
                    }

                    return Object.keys(args);
                },

                merge(existing: Maybe<TCacheTasks>, incoming: Maybe<TCacheTasks>, options) {
                    if (!existing) return incoming;
                    if (!incoming) return existing;

                    const { args } = options as FieldFunctionOptions<IAPIQueryTasksArgs>;

                    if (args?.pagination?.type === 'SCROLL') {
                        const tasks = [...existing.tasks];
                        const refs = new Set(tasks.map(s => s.__ref));

                        for (const task of incoming.tasks) {
                            if (!refs.has(task.__ref)) {
                                tasks.push(task);
                                refs.add(task.__ref);
                            }
                        }

                        return {
                            ...incoming,
                            allIds: incoming.allIds,
                            tasks,
                        };
                    }

                    return incoming;
                },
            },
            messages: {
                keyArgs: ['filter', ['channelId']],
                merge(
                    existing: Maybe<Array<{ __ref: string }>> | undefined,
                    incoming: Maybe<Array<{ __ref: string }>> | undefined,
                    { cache }
                ) {
                    incoming?.forEach(messageRef => {
                        updateMessageClientFieldsInCache(
                            { message: messageRef, isIncoming: true },
                            cache
                        );
                    });

                    const merged = [...(existing || []), ...(incoming || [])];
                    const refsMap = new Map(merged.map(msg => [msg.__ref, msg]));
                    return Array.from(refsMap.values());
                },
            },
            attachments: {
                keyArgs: ['filter', ['channelId']],
                merge(
                    existing: Maybe<Array<{ __ref: string }>> | undefined,
                    incoming: Maybe<Array<{ __ref: string }>> | undefined,
                    { readField, cache }
                ) {
                    incoming?.forEach(attachmentRef => {
                        const messageId = readField<IAPIAttachment['messageId']>(
                            'messageId',
                            attachmentRef
                        );
                        const s3Key = readField<IAPIAttachment['s3Key']>('s3Key', attachmentRef);
                        const progress = readField<IAPIAttachment['progress']>(
                            'progress',
                            attachmentRef
                        );

                        if (typeof messageId === 'string' && typeof s3Key === 'string') {
                            cache.modify<IAPIMessage>({
                                id: GUID2ACID(messageId),
                                fields: {
                                    attachmentS3Keys(existing, { isReference }) {
                                        if (isReference(existing)) {
                                            console.warn(
                                                `Expected array of strings for attachmentS3Keys field but got reference. Skipping cache update`
                                            );

                                            return existing;
                                        }

                                        if (existing) {
                                            return existing.includes(s3Key)
                                                ? existing
                                                : [...existing, s3Key];
                                        }

                                        return [s3Key];
                                    },
                                },
                            });
                        }

                        cache.writeFragment<IAPIAttachmentClientFieldsFragment>({
                            id: cache.identify(attachmentRef),
                            fragment: AttachmentClientFieldsFragmentDoc,
                            data: {
                                __typename: ETypeNames.Attachment,
                                progress: typeof progress === 'number' ? progress : null,
                            },
                        });
                    });

                    const merged = [...(existing || []), ...(incoming || [])];
                    const refsMap = new Map(merged.map(att => [att.__ref, att]));
                    return Array.from(refsMap.values());
                },
            },
        },
    },
    Dashboard: {
        fields: {
            expanded: {
                read(existing, { readField }) {
                    const guid = readField('id');
                    return typeof guid === 'string' && rvExpandedDashboardIds().includes(guid);
                },
            },
        },
    },
    Message: {
        fields: {
            reactions: {
                merge(existing, incoming) {
                    return incoming;
                },
            },

            isIncoming: {
                read(existing = null) {
                    return existing;
                },
            },
            isReadonly: {
                read(existing = null) {
                    return existing;
                },
            },
            isBig: {
                read(existing = null) {
                    return existing;
                },
            },
            attachmentS3Keys: {
                read(existing = null) {
                    return existing;
                },
            },
        },
    },
    TriggerMessage: {
        keyFields: ['messageId'],
    },
    Attachment: {
        keyFields: ['s3Key'],
        fields: {
            progress: {
                merge(existing, incoming) {
                    if (typeof existing !== 'number') return incoming;
                    if (typeof incoming !== 'number') return existing;
                    return Math.max(existing, incoming);
                },
            },
        },
    },
};
